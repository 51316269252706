/**
 * This file is where we do "rehydration" of your RootStore from AsyncStorage.
 * This lets you persist your state between app launches.
 *
 * Navigation state persistence is handled in navigationUtilities.tsx.
 *
 * Note that Fast Refresh doesn't play well with this file, so if you edit this,
 * do a full refresh of your app instead.
 *
 * @refresh reset
 */
import { applySnapshot, IDisposer, onSnapshot } from 'mobx-state-tree';

import * as storage from '../../utils/storage';
import { RootStore, RootStoreSnapshot } from '../RootStore';

/**
 * Set up the root state.
 */
/**
 * The key we'll be saving our state as within async storage.
 */
const ROOT_STATE_STORAGE_KEY = 'root-v1';

let disposer: IDisposer | undefined;
export async function setupRootStore(rootStore: RootStore) {
  let restoredState: RootStoreSnapshot | undefined | null;

  try {
    // load the last known state from AsyncStorage
    const rootStoreData = await storage.load(ROOT_STATE_STORAGE_KEY);

    if (rootStoreData !== null) {
      restoredState = rootStoreData as RootStoreSnapshot;
      applySnapshot(rootStore, restoredState);
    }
  } catch (e) {
    console.log(e);
  }

  // stop tracking state changes if we've already setup
  if (disposer) {
    disposer();
  }

  // track changes & save to AsyncStorage
  disposer = onSnapshot(rootStore, snapshot => storage.save(ROOT_STATE_STORAGE_KEY, snapshot));

  const unsubscribe = () => {
    if (disposer) {
      disposer();
    }
    disposer = undefined;
  };

  return { restoredState, rootStore, unsubscribe };
}
