import Constants from 'expo-constants';

const sortObj = (unsorted: any) =>
  Object.keys(unsorted)
    .sort()
    .reduce((obj: any, key) => {
      obj[key] = unsorted[key];
      return obj;
    }, {});

/**
 * For now, we only use the env type from the mobile app
 *
 *  @type {typeof import('../../env.js').ClientEnv}
 */
// @ts-expect-error: type is already checked by zod
export const ClientEnv: typeof import('../../env.js').ClientEnv = Constants.expoConfig?.extra ?? {};
__DEV__ && console.log('Loaded ClientEnv', JSON.stringify(sortObj(ClientEnv), null, 2));
