import { IncomingMessage, ServerResponse } from 'http';

import { AxiosResponse } from 'axios';
import { JWT } from 'next-auth/jwt';

import { sendWebApiGetRequest } from './WebApiRequester';
import { Account, Customer } from '../contexts';
import { getCustomersFromToken, getEnvUrls, getUserPanelLinks, UserPanelLinks } from '../util/helpers';

const createErrorMessage = (errorMessages: string[], apiResponse: AxiosResponse<unknown>): string[] => {
  return [
    ...errorMessages,
    `On sendWebApiRequest to "${apiResponse.config.url}" an Error occured -> Status: ${apiResponse.status}; Text: ${apiResponse.statusText}`,
  ];
};

const handleError = (errorMessages: string[], req: IncomingMessage, res: ServerResponse) => {
  errorMessages.forEach(message => {
    console.warn(message);
  });

  if (req.url !== '/5xx' && !req.url?.startsWith('/support') && errorMessages.length >= 1) {
    res.setHeader('Location', `/5xx`);
    res.statusCode = 307;
  }
};

interface KeyAndValue {
  key: string;
  value: string;
}

interface HandleInitialPropsOutput {
  account: Account | null;
  customers: Customer[];
  selectedCustomer: Customer | null;
  backofficeUrl: string | null;
  userPanelLinks: UserPanelLinks | null;
}

export const handleGetInitialProps = async (
  req: IncomingMessage,
  res: ServerResponse,
  asPath?: string,
  token?: JWT | null,
  accessToken?: string
): Promise<HandleInitialPropsOutput> => {
  const returnValues: HandleInitialPropsOutput = {
    account: null,
    backofficeUrl: null,
    customers: [],
    selectedCustomer: null,
    userPanelLinks: getUserPanelLinks(),
  };

  if (!token) {
    return returnValues;
  }

  // URLs which should be ignored during token version check
  const disableTokenVersionCheck: string[] = [
    '/auth/signout',
    '/auth/signout?skipLogoutSuccessPage=true',
    '/logout-success',
  ];

  // Check token version
  if (token.version !== process.env.JWT_VERSION && req.url && !disableTokenVersionCheck.includes(req.url)) {
    res.setHeader('Location', '/api/next/auth/logout?skipLogoutSuccessPage=true');
    res.statusCode = 307;

    return returnValues;
  }

  try {
    let errorMessages: string[] = [];

    // Read Customers from Claims
    const customers = getCustomersFromToken(token);

    if (customers) {
      returnValues.customers = customers;
    }

    const selectedCustomerWebApi = await sendWebApiGetRequest<KeyAndValue>(
      'api/v1/UserSettings/selectedCustomer',
      accessToken
    );

    if (selectedCustomerWebApi.status === 200) {
      const selectedCustomer: Customer = JSON.parse(selectedCustomerWebApi.data.value);

      if (returnValues.customers && returnValues.customers.find(customer => customer.id === selectedCustomer.id)) {
        returnValues.selectedCustomer = selectedCustomer;
      }
    } else if (selectedCustomerWebApi.status !== 204) {
      errorMessages = createErrorMessage(errorMessages, selectedCustomerWebApi);
    }
    // Request data from UserInfo Endpoint
    returnValues.account = {
      emailAddress: token.email,
      info: {
        hasCalConnection: false,
        hasChargingTariffContract: false,
        hasInfrastructureContract: false,
        hasSharedChargingTariffChargeDetailRecords: false,
        hasSharedInfrastructureChargeDetailRecords: false,
        hideCompleteProfileInfo: false,
        isAdmin: false,
        isCallCenterAgent: false,
        seesBackofficeLink: false,
        theme: 'default',
      },
    };

    returnValues.backofficeUrl = getEnvUrls().backofficeUrl;

    const userInfoWebApi = await sendWebApiGetRequest('api/v1/UserInfo', accessToken, {
      customerId: returnValues.selectedCustomer?.id ?? undefined,
    });

    // Temporary workaround to get track user activity in the web app
    // After .Net Backend is shutdown, the real user info should be fetched from the backend when startup instead of just once when logging in
    await sendWebApiGetRequest(
      `api/next/v1/customers/${returnValues.selectedCustomer?.id ?? undefined}/user-info`,
      accessToken,
      {
        customerId: returnValues.selectedCustomer?.id ?? undefined,
      }
    );

    if (Math.floor(userInfoWebApi.status / 100) === 2) {
      returnValues.account = { emailAddress: token.email, info: userInfoWebApi.data };
    } else {
      errorMessages = createErrorMessage(errorMessages, userInfoWebApi);
    }

    if (returnValues.account) {
      returnValues.account.info.seesBackofficeLink = returnValues.account.info.isCallCenterAgent;
    }

    handleError(errorMessages, req, res);
  } catch (error) {
    console.warn(error);

    res.setHeader('Location', '/api/next/auth/logout');
    res.statusCode = 307;
  }

  return returnValues;
};
