import defaultColors from './defaultColors';
import evoColors from './tenants/evo/colors';
import vkwColors from './tenants/vkw/colors';
import { ClientEnv } from '../env/index';

const colors: DefaultColors = (() => {
  switch (ClientEnv.TENANT) {
    case 'vkw':
      return vkwColors;
    case 'evo':
      return evoColors;
    default:
      return defaultColors;
  }
})();

export { colors };
export type DefaultColors = typeof defaultColors;
