import { ClientEnv } from '../env';

let BASE_URL = process.env['WEB_URL'] ?? ClientEnv.WEB_URL ?? '';

//remove / if it is already in the url
if (BASE_URL.endsWith('/')) {
  BASE_URL = BASE_URL.slice(0, -1);
}

export const apiFetch = (endpoint: string, options?: RequestInit) => {
  const url = `${BASE_URL}${endpoint}`;
  return fetch(`${url}`, options);
};
